import Icons from '@aurora/shared-client/icons';
import type IconTypes from '@aurora/shared-client/public/static/graphics/processed/enums';
import { QuiltWrapperWidgetCategory, WidgetCategory } from '@aurora/shared-client/types/enums';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import endUserComponentRegistry from '../../../features/endUserComponentRegistry';

export interface WidgetInfo {
  /**
   * The widget category
   */
  category: WidgetCategory | QuiltWrapperWidgetCategory;
  /**
   * The reference to the widget icon
   */
  icon: IconTypes;
  /**
   * Title for external widget
   */
  title?: string;
  /**
   * Description for external widget
   */
  description?: string;
}

export const widgetIconByCategoryMap: Record<WidgetCategory | QuiltWrapperWidgetCategory, Icons> = {
  [WidgetCategory.CONTENT]: Icons.WidgetContentIcon,
  [WidgetCategory.PEOPLE]: Icons.WidgetPeopleIcon,
  [WidgetCategory.PLACES]: Icons.WidgetPlacesIcon,
  [WidgetCategory.CUSTOM_CONTENT]: Icons.WidgetCustomIcon,
  [WidgetCategory.CUSTOM_HTML_CONTENT]: Icons.WidgetStaticIcon,
  [QuiltWrapperWidgetCategory.COMMON]: Icons.CategoryCommonIcon
};

/**
 * A Record of widgets that may be added to pages by end users via PageBuilder
 */
export const WidgetRegistry: Partial<Record<EndUserComponent, WidgetInfo>> = {
  // content widgets
  [EndUserComponent.MESSAGE_LIST_FOR_NODE_BY_RECENT_ACTIVITY_WIDGET]: {
    category: WidgetCategory.CONTENT,
    icon: widgetIconByCategoryMap[WidgetCategory.CONTENT]
  },
  [EndUserComponent.MESSAGE_LIST_FOR_NODE_BY_TOP_CONTENT_WIDGET]: {
    category: WidgetCategory.CONTENT,
    icon: widgetIconByCategoryMap[WidgetCategory.CONTENT]
  },
  [EndUserComponent.OCCASION_LIST_FOR_NODE_WIDGET]: {
    category: WidgetCategory.CONTENT,
    icon: widgetIconByCategoryMap[WidgetCategory.CONTENT]
  },
  [EndUserComponent.FEATURED_CONTENT_WIDGET]: {
    category: WidgetCategory.CONTENT,
    icon: widgetIconByCategoryMap[WidgetCategory.CONTENT]
  },
  [EndUserComponent.TAG_WIDGET]: {
    category: WidgetCategory.CONTENT,
    icon: widgetIconByCategoryMap[WidgetCategory.CONTENT]
  },
  [EndUserComponent.RELATED_CONTENT_WIDGET]: {
    category: WidgetCategory.CONTENT,
    icon: widgetIconByCategoryMap[WidgetCategory.CONTENT]
  },
  [EndUserComponent.NODE_ACTION_BUTTON_WIDGET]: {
    category: WidgetCategory.CONTENT,
    icon: widgetIconByCategoryMap[WidgetCategory.CONTENT]
  },
  // people widgets
  [EndUserComponent.USER_LIST_FOR_NODE_BY_JOIN_DATE_WIDGET]: {
    category: WidgetCategory.PEOPLE,
    icon: widgetIconByCategoryMap[WidgetCategory.PEOPLE]
  },
  [EndUserComponent.USER_LIST_FOR_NODE_BY_TOP_CONTRIBUTORS_WIDGET]: {
    category: WidgetCategory.PEOPLE,
    icon: widgetIconByCategoryMap[WidgetCategory.PEOPLE]
  },
  [EndUserComponent.USER_ONLINE_LIST_FOR_COMMUNITY_WIDGET]: {
    category: WidgetCategory.PEOPLE,
    icon: widgetIconByCategoryMap[WidgetCategory.PEOPLE]
  },
  // places widgets
  [EndUserComponent.NODE_INFORMATION_WIDGET]: {
    category: WidgetCategory.PLACES,
    icon: widgetIconByCategoryMap[WidgetCategory.PLACES]
  },
  [EndUserComponent.PLACES_WIDGET]: {
    category: WidgetCategory.PLACES,
    icon: widgetIconByCategoryMap[WidgetCategory.PLACES]
  },
  [EndUserComponent.FEATURED_PLACES_WIDGET]: {
    category: WidgetCategory.PLACES,
    icon: widgetIconByCategoryMap[WidgetCategory.PLACES]
  },
  [EndUserComponent.IDEA_MESSAGES_WIDGET]: {
    category: WidgetCategory.CONTENT,
    icon: widgetIconByCategoryMap[WidgetCategory.CONTENT]
  },
  [EndUserComponent.IDEA_SEARCH_BEFORE_POST_WIDGET]: {
    category: WidgetCategory.CONTENT,
    icon: widgetIconByCategoryMap[WidgetCategory.CONTENT]
  },
  [EndUserComponent.FEATURED_IDEA_STATUSES_WIDGET]: {
    category: WidgetCategory.CONTENT,
    icon: widgetIconByCategoryMap[WidgetCategory.CONTENT]
  },
  [EndUserComponent.GUIDES_WIDGET]: {
    category: WidgetCategory.CONTENT,
    icon: widgetIconByCategoryMap[WidgetCategory.CONTENT]
  }
};

/**
 * A Record of widgets that may be added to quilt wrapper by end users via PageBuilder
 */
export const CommonWidgetRegistry: Partial<Record<EndUserComponent, WidgetInfo>> = {
  [EndUserComponent.NAVBAR_WIDGET]: {
    category: QuiltWrapperWidgetCategory.COMMON,
    icon: widgetIconByCategoryMap[QuiltWrapperWidgetCategory.COMMON]
  },
  [EndUserComponent.BREADCRUMB_WIDGET]: {
    category: QuiltWrapperWidgetCategory.COMMON,
    icon: widgetIconByCategoryMap[QuiltWrapperWidgetCategory.COMMON]
  },
  [EndUserComponent.BANNER_WIDGET]: {
    category: QuiltWrapperWidgetCategory.COMMON,
    icon: widgetIconByCategoryMap[QuiltWrapperWidgetCategory.COMMON]
  },
  [EndUserComponent.FOOTER_WIDGET]: {
    category: QuiltWrapperWidgetCategory.COMMON,
    icon: widgetIconByCategoryMap[QuiltWrapperWidgetCategory.COMMON]
  }
};

export function appendWidgets(feature: string): void {
  const widgets = endUserComponentRegistry.getAllWidgetDescriptorsForFeature(feature);
  widgets.forEach(widget => {
    const { id, category, title, description } = widget;
    if (category) {
      WidgetRegistry[id] = {
        category,
        icon: widgetIconByCategoryMap[category],
        title,
        description
      };
    }
  });
}

interface CustomComponentInfo {
  /**
   * The widgetId path for the specified static/dynamic widget.
   */
  widgetId: string;
  /**
   * The widget category
   */
  category: WidgetCategory;
  /**
   * The reference to the widget icon
   */
  icon: IconTypes;
}

/**
 * A Record of custom components that may be added to any pages.
 */
export const CustomComponentRegistry: Record<string, CustomComponentInfo> = {
  static: {
    widgetId: EndUserComponent.CUSTOM_COMPONENT_WIDGET,
    category: WidgetCategory.CUSTOM_CONTENT,
    icon: widgetIconByCategoryMap[WidgetCategory.CUSTOM_CONTENT]
  }
};

/**
 * A Record of text/html components that may be added to any pages.
 */
export const TextHtmlComponentRegistry: Record<string, CustomComponentInfo> = {
  static: {
    widgetId: EndUserComponent.CUSTOM_COMPONENT_WIDGET,
    category: WidgetCategory.CUSTOM_HTML_CONTENT,
    icon: widgetIconByCategoryMap[WidgetCategory.CUSTOM_HTML_CONTENT]
  }
};
